import React from 'react'
import { Card } from '@accodeing/gatsby-theme-heimr'
import classNames from 'classnames'

const PricingCard = ({
  children,
  className,
  deselect,
  hidden,
  onClick,
  price,
  prominent = false,
  selected,
  title,
}) => {
  const cardClasses = classNames('card', className, {
    'card--hidden': hidden,
    'card--selected': selected,
    'card--prominent': prominent,
    'card--not-prominent': !prominent && !selected,
  })

  const selectButtonClasses = classNames(
    'button button--themed button--outlined button--card',
    {
      'button--hidden': selected,
    }
  )

  const deselectButtonClasses = classNames(
    'button button--themed button--card',
    {
      'button--hidden': !selected,
    }
  )

  return (
    <Card className={cardClasses}>
      <h3 className='card__title card__title--centered'>{title}</h3>
      <h4 className='card__sub-title'>från {price}:-/mån</h4>
      {children}
      <div className='card__button-wrapper'>
        <button onClick={() => onClick()} className={selectButtonClasses}>
          Beställ
        </button>
        <button className={deselectButtonClasses} onClick={() => deselect()}>
          Byt paket
        </button>
      </div>
    </Card>
  )
}

export default PricingCard
